import * as React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import descriptionData from "../content/description.json";
import ProductContainer from "../components/productContainer";

const About = ({  }) => {
  return (
    <Layout>
      <Seo
        title="About Collective Cosmetics"
        description="Collective Cosmetics offers a curated selection of latest dress styles from leading designers and brands, providing a time-efficient shopping experience. Our comprehensive collection serves all your dress needs, making us the ultimate one-stop-shop for fashionable attire."
      />

      <div className="descriptions-container container px-18 max-w-screen-xl mx-auto">
        <h1 class="text-2xl mt-5 mb-5 font-bold">About Collective Cosmetics</h1>
        <p>
        Welcome to Collective Cosmetics, your ultimate destination for all things beauty. We are dedicated to take the stress out of endless shopping by consolidating the finest cosmetics from multiple brands into one easily accessible platform. 
        </p>

        <p>
        Our diverse and high-quality collection encompasses varied and upscale beauty products. Our expansive selections meet all of your beauty needs, whether you are in search of the perfect toner, daily makeup essentials, invigorating face masks or anything in-between. 
        </p>

        <p>
        Aside from our extensive product collection, Collective Cosmetics prides itself on serving as a beauty consultancy and a source of inspiration. Our regularly updated blog brings a wealth of information and guidance right at your fingertips, featuring insight from fashion experts, engaging interviews, and step-by-step guides to elevate your style. 
        </p>

        <p>
        Explore Collective Cosmetics today and allow us to guide you in finding the ultimate cosmetics that won't just amplify your external beauty but boost your inner confidence as well. Embark on a beauty journey that feels personally tailored to not just help you look, but also genuinely feel, outstanding!
        </p>
      </div>
    </Layout>
  );
};

export default About;
